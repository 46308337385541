GameStartSound
<template>
  <WithAudioContext #default="{ unmute, mute }" v-if="!isPaused">
    <SmartMedia
      :paused="isPaused"
      :src="require('@/assets/game-start-countdown.mp3')"
      :volume="0.5"
      @play="mute"
      @pause="unmute"
      @end="
        unmute
        isPaused = true
      "
    />
  </WithAudioContext>
</template>

<script>
import { defineComponent, ref, computed, watch } from "@vue/composition-api"

import WithAudioContext from "@/modules/audio/components/WithAudioContext"
import SmartMedia from "@/modules/smart-media/components/SmartMedia.vue"
import useStore from "@/use/useStore"

const TIME_BEFORE_START = 6000
export default defineComponent({
  name: "GameStartSound",
  components: {
    WithAudioContext,
    SmartMedia
  },
  setup() {
    const isPaused = ref(true)
    const { store } = useStore()
    const globalTime = computed(() => store.getters.time)
    const currentRoomStartTimestamp = computed(
      () => store.getters.actualGame?.startTimestamp
    )
    watch(
      globalTime,
      value => {
        const timeLeft = currentRoomStartTimestamp.value - value

        if (timeLeft > 0 && timeLeft < TIME_BEFORE_START) {
          isPaused.value = false
        }
      },
      { immediate: true }
    )
    return { isPaused }
  }
})
</script>
