var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isPaused
    ? _c("WithAudioContext", {
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function (ref) {
                var unmute = ref.unmute
                var mute = ref.mute
                return [
                  _c("SmartMedia", {
                    attrs: {
                      paused: _vm.isPaused,
                      src: require("@/assets/game-start-countdown.mp3"),
                      volume: 0.5,
                    },
                    on: {
                      play: mute,
                      pause: unmute,
                      end: function ($event) {
                        unmute
                        _vm.isPaused = true
                      },
                    },
                  }),
                ]
              },
            },
          ],
          null,
          false,
          504880671
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }